<template>
  <div class="d-flex align-items-center justify-content-center flex-column">
    <div class="image-header">
      <v-btn class="me-2" @click="toggleRawImage">
        <fa-icon icon="search"></fa-icon>
      </v-btn>
      <a :href="imageLink" @click.stop>
        <v-btn class="me-2">
          <fa-icon icon="cloud-download-alt"></fa-icon>
        </v-btn>
      </a>
      <v-btn class="me-2" @click="toggleModalUpload">
        <fa-icon icon="cloud-upload-alt"></fa-icon>
      </v-btn>
      <v-btn class="me-3" @click="prevImage">
        <fa-icon icon="chevron-left"></fa-icon>
      </v-btn>
      <span>{{ time }}</span>
      <v-btn class="ms-3" @click="nextImage">
        <fa-icon icon="chevron-right"></fa-icon>
      </v-btn>
    </div>
    <img v-show="!isRawImageShown" :src="imageLink" />
    <img v-show="isRawImageShown" :src="rawImageLink" />
    <div v-show="!loading" class="image-footer">
      <table>
        <tr>
          <th>Estadístico</th>
          <th>Valor</th>
        </tr>
        <tr v-for="stat in statistics" :key="stat.key">
          <td>{{ stat.label }}</td>
          <td>
            <span class="plugging-span">{{ stat.value }}</span>
          </td>
          <td>
            <fa-icon
              v-if="stat.status != null && stat.status === 'ok'"
              class="plugging-ok"
              icon="check-circle"
            ></fa-icon>
            <fa-icon
              v-else-if="stat.status != null && stat.status === 'warn'"
              class="plugging-warn"
              icon="exclamation-circle"
            ></fa-icon>
            <fa-icon
              v-else-if="stat.status != null && stat.status === 'bad'"
              class="plugging-bad"
              icon="times-circle"
            ></fa-icon>
          </td>
        </tr>
      </table>
    </div>
    <modal-upload-image
      :show="isModalUploadOpen"
      @toggle="toggleModalUpload"
    ></modal-upload-image>
  </div>
</template>

<script>
import { getImage, listImages } from "../../api/dredges";
import debounce from "lodash/debounce";
import { formatTime } from "../../utils/date";
import ModalUploadImage from "./ModalUploadImage.vue";

export default {
  components: { ModalUploadImage },
  props: ["dredge", "section", "date"],
  data() {
    return {
      loading: true,
      isModalUploadOpen: false,
      images: [],
      image: null,
      imageLink: null,
      rawImageLink: null,
      isRawImageShown: false,
      statistics: [],
      statsToDisplay: {
        avg_hole_count: {
          label: "Cantidad promedio de orificios",
          status: stats => {
            if (stats.avg_hole_count < 200) return "bad";
            else if (stats.avg_hole_count < 800) return "warn";
            return "ok";
          }
        },
        hole_count: {
          label: "Cantidad de orificios destapados",
          status: stats => {
            const holeCount = stats.hole_count;
            if (holeCount < 0.5 * stats.avg_hole_count) return "bad";
            else if (holeCount < 0.75 * stats.avg_hole_count) return "warn";
            return "ok";
          },
          value: stats => stats.hole_count
        },
        avg_hole_area: {
          label: "Área promedio de orificio global",
          status: stats => {
            if (stats.avg_hole_area < 150) return "bad";
            else if (stats.avg_hole_area < 180) return "warn";
            return "ok";
          },
          value: stats => Math.round(stats.avg_hole_area)
        },
        hole_area: {
          label: "Área promedio de orificio en imagen",
          status: stats => {
            if (stats.hole_area < 0.75 * stats.avg_hole_area) return "bad";
            else if (stats.hole_area < 0.9 * stats.avg_hole_area) return "warn";
            return "ok";
          },
          value: stats => Math.round(stats.hole_area)
        },
        plugging_perc: {
          label: "Porcentaje de taponamiento",
          status: stats => {
            if (stats.plugging_perc < 25) return "ok";
            else if (stats.plugging_perc < 75) return "warn";
            return "bad";
          },
          value: stats => `${(stats.plugging_perc * 100).toFixed(2)}%`
        },
        discard: {
          label: "Foto descartada",
          status: stats => {
            if (stats.discard) return "bad";
            return "ok";
          },
          value: stats => (stats.discard ? "Si" : "No")
        }
      }
    };
  },
  methods: {
    fetchImages: debounce(async function() {
      const { dredge, section, date } = this;
      if (dredge == null || section == null || !Date.parse(date)) {
        return;
      }
      const response = await listImages({ dredge, section, date });
      const { results: images } = await response.json();
      this.images = images;
      this.image = images[images.length - 1];
    }, 500),
    fetchImage: debounce(async function() {
      if (this.image == null) {
        return;
      }
      this.loading = true;
      const { dredge, section } = this;
      const response = await getImage({
        dredge,
        section,
        datetime: this.image.datetime,
        raw: this.isRawImageShown
      });
      const image = await response.json();
      if (this.isRawImageShown) {
        this.rawImageLink = image.url;
      } else {
        this.imageLink = image.url;
      }
      this.loading = false;
    }, 500),
    nextImage() {
      const currImgIndex = this.images.findIndex(
        image => image.datetime === this.image.datetime
      );
      if (currImgIndex === -1) return;
      this.image = this.images[
        Math.min(currImgIndex + 1, this.images.length - 1)
      ];
    },
    prevImage() {
      const currImgIndex = this.images.findIndex(
        image => image.datetime === this.image.datetime
      );
      if (currImgIndex === -1) return;
      this.image = this.images[Math.max(currImgIndex - 1, 0)];
    },
    toggleRawImage() {
      this.isRawImageShown = !this.isRawImageShown;
    },
    toggleModalUpload() {
      this.isModalUploadOpen = !this.isModalUploadOpen;
    }
  },
  computed: {
    time() {
      if (!this.image) {
        return "";
      }
      return formatTime(new Date(this.image.datetime));
    }
  },
  watch: {
    dredge: "fetchImages",
    section: "fetchImages",
    date: "fetchImages",
    image() {
      this.imageLink = null;
      this.rawImageLink = null;
      this.statistics = [];
      Object.entries(this.statsToDisplay).forEach(([stat, props]) => {
        if (this.image[stat] == null) return;
        const result = {
          key: stat,
          value: this.image[stat],
          label: props.label
        };
        if (props.status != null) {
          result.status = props.status(this.image);
        }
        if (props.value != null) {
          result.value = props.value(this.image);
        }
        this.statistics.push(result);
      });
      this.fetchImage();
    },
    isRawImageShown(newVal) {
      if (
        (newVal && this.rawImageLink == null) ||
        (!newVal && this.imageLink == null)
      ) {
        this.fetchImage();
      }
    }
  }
};
</script>

<style scoped>
.image-header {
  width: 100%;
  max-width: 640px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
img {
  width: 100%;
  max-width: 640px;
  max-height: 480px;
}
button {
  height: 40px;
  width: 40px;
  padding: 0;
}

label {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 2px;
}
.image-footer {
  width: 100%;
  max-width: 640px;
}

.image-footer table {
  width: 100%;
}

.image-footer table tr {
  border-bottom: 1px solid #eee;
}

.image-footer table td {
  padding: 0.75rem;
}

.image-footer table th {
  padding: 0.75rem;
}

.plugging-ok {
  color: #4caf50;
  margin-bottom: 2px;
}
.plugging-warn {
  color: #ffc107;
}
.plugging-bad {
  color: #f44336;
  margin-bottom: -2px;
}
.plugging-span {
  margin-right: 0.5rem;
}
</style>
