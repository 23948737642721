<template>
  <div class="dropdown">
    <div
      class="dropdown-toggle"
      data-bs-toggle="dropdown"
      type="button"
      aria-expanded="false"
    >
      <span>{{ textOfValue }}</span>
      <fa-icon class="default-chevron" icon="chevron-down"></fa-icon>
    </div>
    <ul class="dropdown-menu" aria-labelledby="dredge-toggle">
      <li
        class="dropdown-item"
        v-for="item in items"
        :key="item.value"
        @click="$emit('update:value', item.value)"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: null
    }
  },
  emits: ["update:value"],
  computed: {
    textOfValue() {
      return this.items.find(item => item.value === this.value)?.text ?? "";
    }
  }
};
</script>

<style scoped>
.dropdown {
  color: #222025;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-toggle {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.default-chevron {
  font-size: 12px;
  margin-left: 1rem;
  height: 24px;
}
.dropdown-item {
  color: #222025;
}
.dropdown-item:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.dropdown-item:active {
  background-color: #e6e6e6;
}
</style>
