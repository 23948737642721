<template>
  <div>
    <!-- eslint-disable vue/no-v-model-argument -->
    <HeaderGriddleSelection
      v-model:dredge="dredge"
      v-model:section="section"
      v-model:date="date"
    />
    <body-griddle-selection
      :dredge="dredge"
      :section="section"
      :date="date"
    ></body-griddle-selection>
  </div>
</template>

<script>
import HeaderGriddleSelection from "./HeaderGriddleSelection.vue";
import BodyGriddleSelection from "./BodyGriddleSelection.vue";

export default {
  components: { HeaderGriddleSelection, BodyGriddleSelection },
  data() {
    return {
      dredge: null,
      section: null,
      date: new Date().toISOString().substring(0, 10)
    };
  }
};
</script>
