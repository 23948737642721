<template>
  <the-page>
    <h2>
      Taponamiento en Planchas de Criba
    </h2>
    <TheGriddleSelection />
  </the-page>
</template>

<script>
import TheGriddleSelection from "../components/dredges/TheGriddleSelection.vue";
import ThePage from "../components/shared/ThePage.vue";

export default {
  components: {
    ThePage,
    TheGriddleSelection
  }
};
</script>
