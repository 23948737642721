<template>
  <modal :show="show" @toggle="onCancelUpload">
    <template v-slot:header>
      <h5 class="my-0">
        Subir foto de plancha
      </h5>
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <div class="mb-2">
          <label for="imageDate">Fecha y hora de la imagen</label>
          <input
            id="imageDate"
            :class="['form-control', { 'error-input': errors.imageDate }]"
            type="text"
            v-model="imageDate"
            @focus="delete errors.imageDate"
            placeholder="AAAA-MM-DDTHH:MM:SS"
          />
          <span v-if="errors.imageDate" class="error-message">
            {{ errors.imageDate }}
          </span>
        </div>
        <div class="d-flex mb-3 justify-content-between">
          <div class="pe-2 w-50">
            <label for="dredge">Draga</label>
            <input
              id="dredge"
              :class="['form-control', { 'error-input': errors.dredge }]"
              type="text"
              v-model="dredge"
              @focus="delete errors.dredge"
            />
            <span v-if="errors.dredge" class="error-message">
              {{ errors.dredge }}
            </span>
          </div>
          <div class="ps-2 w-50">
            <label for="section">Sección</label>
            <input
              id="section"
              :class="['form-control', { 'error-input': errors.section }]"
              type="text"
              v-model="section"
              @focus="delete errors.section"
            />
            <span v-if="errors.section" class="error-message">
              {{ errors.section }}
            </span>
          </div>
        </div>
        <img class="mb-2" id="image" />
        <button-upload-file
          @focus="delete errors.imageFile"
          accept="image/*"
          @input="onSelectImage"
        >
          <fa-icon icon="upload"></fa-icon>
          Seleccionar imagen
        </button-upload-file>
        <span v-if="errors.imageFile" class="error-message">
          {{ errors.imageFile }}
        </span>
      </div>
    </template>
    <template v-slot:footer>
      <v-btn @click="onCancelUpload">
        Cancelar
      </v-btn>
      <v-btn @click="onUploadImage" color="primary">
        Subir
      </v-btn>
    </template>
  </modal>
</template>

<script>
import { ref } from "vue";
import Modal from "../../components/shared/Modal/Modal.vue";
import { formatDate, formatTime } from "../../utils/date";
import ButtonUploadFile from "../shared/Button/ButtonUploadFile.vue";
import { uploadImage } from "../../api/dredges";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ["toggle", "uploaded"],
  components: {
    Modal,
    ButtonUploadFile
  },
  setup(props, { emit }) {
    const now = new Date();
    const imageDate = ref(`${formatDate(now)}T${formatTime(now)}:00`);
    const dredge = ref(null);
    const section = ref(null);
    const errors = ref({});

    const imageFile = ref(null);
    const onSelectImage = event => {
      if (event.target.files?.length > 0) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const dataURL = reader.result;
          const output = document.getElementById("image");
          output.src = dataURL;
          imageFile.value = file;
        };
        reader.readAsDataURL(file);
      }
    };
    const validate = () => {
      errors.value = {};
      if (!imageFile.value) {
        errors.value.imageFile = "Seleccione una imagen";
      }
      if (!dredge.value) {
        errors.value.dredge = "Seleccione una draga";
      } else if (!Number.isInteger(parseInt(dredge.value))) {
        errors.value.dredge = "El valor debe ser un número entero";
      }
      if (!section.value) {
        errors.value.section = "Seleccione una sección";
      } else if (!Number.isInteger(parseInt(section.value))) {
        errors.value.section = "El valor debe ser un número entero";
      }
      return Object.keys(errors.value).length === 0;
    };
    const onUploadImage = () => {
      const isValid = validate();
      if (!isValid) {
        return;
      }
      const data = {
        image: imageFile.value,
        imageDate: imageDate.value,
        dredge: parseInt(dredge.value),
        section: parseInt(section.value)
      };
      const response = uploadImage(data);
      if (response.status === 200) {
        emit("uploaded", data);
      }
      emit("toggle");
    };
    const onCancelUpload = () => {
      errors.value = {};
      section.value = null;
      dredge.value = null;
      imageFile.value = null;
      const now = new Date();
      imageDate.value = `${formatDate(now)}T${formatTime(now)}:00`;
      const output = document.getElementById("image");
      output.src = "";
      emit("toggle");
    };
    return {
      imageDate,
      errors,
      dredge,
      section,
      onSelectImage,
      onUploadImage,
      onCancelUpload
    };
  }
};
</script>

<style scoped>
.error-message {
  font-size: 12px;
  color: #ff0000;
}
</style>
