import { makeRequest } from "./utils";
import { DateTime } from "luxon";

export const listDredges = async ({ date } = {}) => {
  let url = "dredges/plugged";
  if (date != null) {
    url += `?date=${date}&tz=${DateTime.local().zoneName}`;
  }
  return await makeRequest(url);
};

export const listImages = async ({ dredge, section, date }) => {
  let url = `dredges/plugged/${dredge}/sections/${section}/images`;
  if (date != null) {
    url += `?date=${date}&tz=${DateTime.local().zoneName}`;
  }
  return await makeRequest(url);
};

export const getImage = async ({ dredge, section, datetime, raw }) => {
  let url = `dredges/plugged/${dredge}/sections/${section}/images/${datetime}?raw=${raw}`;
  return await makeRequest(url);
};

const getUploadProps = async (dredge, section, imageDate, file) => {
  const ext = file.name.split(".").pop();
  const filename = `${dredge}_${section}_${imageDate}.${ext}`;
  const uploadURL = `dredges/plugged/${dredge}/sections/${section}/images/?dt=${imageDate}&ext=${ext}`;
  const response = await makeRequest(uploadURL, { method: "POST" });
  if (response.status != 200) {
    return [null, null];
  }
  const { url, fields } = await response.json();
  const data = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    data.append(key, value);
  });
  data.append("file", file, filename);
  return [url, data];
};

export const uploadImage = async ({ dredge, section, imageDate, image }) => {
  const [url, data] = await getUploadProps(dredge, section, imageDate, image);
  if (url == null) {
    return null;
  }
  return await fetch(url, { method: "POST", body: data });
};
