<template>
  <ul
    class="d-flex align-items-center justify-content-center my-4 header-container"
  >
    <div class="d-flex flex-column mx-2 my-2 form-item">
      <label for="">Draga</label>
      <dropdown-list
        :value="dredge"
        @update:value="$emit('update:dredge', $event)"
        :items="dredgeItems"
      ></dropdown-list>
    </div>
    <div class="d-flex flex-column mx-2 my-2 form-item">
      <label for="">Sección</label>
      <dropdown-list
        :value="section"
        @update:value="$emit('update:section', $event)"
        :items="sectionItems"
      ></dropdown-list>
    </div>
    <div class="d-flex flex-column mx-2 my-2 form-item">
      <label for="">Fecha</label>
      <div class="date-container" @click="dateInputRef.focus()">
        <input
          ref="date-input"
          class="date-input"
          placeholder="AAAA-MM-DD"
          :value="date"
          @keyup="dateInputKeyUp($event)"
          @blur="dateInputBlur($event)"
        />
      </div>
    </div>
  </ul>
</template>

<script>
import { listDredges } from "../../api/dredges";
import DropdownList from "../shared/Dropdown/DropdownList";

export default {
  components: {
    DropdownList
  },
  props: ["dredge", "section", "date"],
  emits: ["update:dredge", "update:section", "update:date"],
  data() {
    return {
      dateInputRef: null,
      dredges: []
    };
  },
  created() {
    this.dateInputRef = this.$refs["date-input"];
    this.fetchDredges({ date: this.date });
  },
  mounted() {
    this.dateInputRef = this.$refs["date-input"];
  },
  methods: {
    fetchDredges() {
      const fetchAux = async () => {
        const response = await listDredges({ date: this.date });
        const { results: dredges } = await response.json();
        this.dredges = dredges;
        if (this.dredges.length > 0 && this.dredges[0].sections.length > 0) {
          this.$emit("update:dredge", this.dredges[0].id);
          this.$emit("update:section", this.dredges[0].sections[0].id);
        }
      };
      fetchAux();
    },
    dateInputKeyUp(event) {
      if (event.key === "Enter") {
        this.dateInputRef.blur();
      }
    },
    dateInputBlur(event) {
      const parsed = Date.parse(event.target.value);
      let date = this.date;
      if (parsed) {
        date = new Date(parsed).toISOString().substring(0, 10);
        this.$emit("update:date", date);
      }
      this.dateInputRef.value = date;
    }
  },
  computed: {
    sections() {
      return (
        this.dredges.find(dredge => dredge.id === this.dredge)?.sections ?? []
      );
    },
    dredgeItems() {
      return this.dredges.map(dredge => ({
        value: dredge.id,
        text: `Draga ${dredge.id}`
      }));
    },
    sectionItems() {
      return this.sections.map(section => ({
        value: section.id,
        text: `Sección ${section.id}`
      }));
    }
  },
  watch: {
    date(prev, curr) {
      console.log("Watched!");
      if (prev !== curr) {
        this.fetchDredges({ date: this.date });
      }
    }
  }
};
</script>

<style scoped>
label {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 2px;
  font-weight: 500;
}
@media (max-width: 768px) {
  .form-item {
    width: 100%;
  }
  .date-container {
    width: 100%;
  }
  .header-container {
    flex-direction: column;
  }
}
.date-container {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date-input {
  border: none;
  padding: 0;
  outline: none;
  min-width: 0;
  width: 100px;
  font-weight: 300;
}
</style>
